import React from "react";
import { Row, Col, Space, Typography, Button, Divider } from "antd";
import HowitWorksBanner from "../assets/images/howitworksbanner.jpg";
import {
  PieChartTwoTone,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import "../assets/HowItworks.css";

const { Title, Paragraph, Text, Link } = Typography;

export default function HowItWorks() {
  return (
    <div className="how_it_works_banner_container">
      <div className="hiwb_Bluebg"></div>
      <Row gutter={[0, 16]}>
        <Col lg={8} xl={8} xxl={8} md={8} xs={24} sm={24} style={{ zIndex: 99999 }}>
          <div className="bannerText">
            <span>
              Not all vaccines are the same and not all individuals respond
              equally to them.
            </span>
          </div>
        </Col>
        <Col lg={16} xxl={16} xl={16} md={16} xs={24} sm={24}>
          <img src={HowitWorksBanner} />
        </Col>
      </Row>
      <Space className="mt-5" size="large" direction="vertical">
        <p className="howitworksP">
          ABSURE is a novel mobile device technology that allows individuals to
          track their antibody response, so you are sure to be protected.
        </p>
        <p className="howitworksP">
          We are looking for a limited number of vaccinated individuals to test
          the product and join the study at one of our facilities and
          recruitment sites. In exchange, information about your antibody
          response along with other biomarkers would be provided free of charge!
        </p>
        <Row gutter={[16, 16]}>
          <Col lg={19}>
            <Space size={60} direction="vertical">
              <div>
                <Title level={2}>Study Overview</Title>
                <Row style={{marginTop:"50px"}} gutter={[16, 16]}>
                  <Col lg={7} xl={7} xxl={7} md={8} xs={24} sm={24}>
                    <div
                      className="studyOverviewCol"
                    >
                      <PieChartTwoTone style={{ fontSize: "5em" }} />
                      <Paragraph>
                        By participating, you will help us refine the technology
                        to track and measure antibody response in your body in
                        15 minutes
                      </Paragraph>
                    </div>
                  </Col>
                  <Col lg={7} xl={7} xxl={7} md={8} xs={24} sm={24}>
                    <div
                      className="studyOverviewCol"
                    >
                      <PieChartTwoTone style={{ fontSize: "5em" }} />
                      <Paragraph>
                        By participating, you will help us refine the technology
                        to track and measure antibody response in your body in
                        15 minutes
                      </Paragraph>
                    </div>
                  </Col>
                  <Col lg={7} xl={7} xxl={7} md={8} xs={24} sm={24}>
                    <div
                      className="studyOverviewCol"
                    >
                      <PieChartTwoTone style={{ fontSize: "5em" }} />
                      <Paragraph>
                        By participating, you will help us refine the technology
                        to track and measure antibody response in your body in
                        15 minutes
                      </Paragraph>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Title level={2}>What is the purpose of this study?</Title>
                <Paragraph style={{maxWidth: '85vw'}}>
                  It was popularised in the 1960s with the release of Letraset
                  sheets containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum.
                </Paragraph>
              </div>
              <div>
              <Title level={2}>Who can join?</Title>
              <Row style={{marginTop:"30px"}}  gutter={[16, 16]}>
                <Col lg={10} xl={10} xxl={10} md={12} xs={24} sm={24}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "6.5em",
                    }}
                  >
                    <CheckOutlined
                      style={{
                        fontSize: "3em",
                        width: "min-content",
                        color: "#52c41a",
                      }}
                    />
                    <Title level={3}>You may be able to join if...</Title>
                  </div>
                  <Paragraph>
                    <ul>
                      <li>
                        By participating, you will help us refine the technology
                        to track and measure antibody response in your body in
                        15 minutes
                      </li>
                      <li>
                        By participating, you will help us refine the technology
                        to track and measure antibody response in your body in
                        15 minutes
                      </li>
                      <li>
                        By participating, you will help us refine the technology
                        to track and measure antibody response in your body in
                        15 minutes
                      </li>
                    </ul>
                  </Paragraph>
                </Col>
                <Col lg={10} xl={10} xxl={10} md={12} xs={24} sm={24}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "6.5em",
                    }}
                  >
                    <CloseOutlined
                      style={{
                        fontSize: "3em",
                        width: "min-content",
                        color: "red",
                      }}
                    />
                    <Title level={3}>You can't join if...</Title>
                  </div>
                  <Paragraph>
                    <ul>
                      <li>
                        By participating, you will help us refine the technology
                        to track and measure antibody response in your body in
                        15 minutes
                      </li>
                      <li>
                        By participating, you will help us refine the technology
                        to track and measure antibody response in your body in
                        15 minutes
                      </li>
                      <li>
                        By participating, you will help us refine the technology
                        to track and measure antibody response in your body in
                        15 minutes
                      </li>
                    </ul>
                  </Paragraph>
                </Col>
              </Row>
            </div>
            </Space>
        
          </Col>
          <Col lg={5}>
            <div className="sideMenus">
              <Divider plain style={{ borderTop: "5px solid #096dd9" }} />
              <Space
                style={{ padding: "2em" }}
                size="large"
                direction="vertical"
              >
                <Title level={3}>Interested in learning more?</Title>
                <Button type="primary" size="large" block>
                  Contact Us
                </Button>
              </Space>
              <Divider plain style={{ borderTop: "5px solid #096dd9" }} />
              <Space
                style={{ padding: "2em" }}
                size="large"
                direction="vertical"
              >
                <Title level={3}>Our Team</Title>
                <Title level={4}>Person Name</Title>
                <Title level={4}>Person Name</Title>
              </Space>
              <Divider plain style={{ borderTop: "8px solid #096dd9" }} />
            </div>
          </Col>
        </Row>
        <div>
          <Title level={2}>Frequently asked questions</Title>
          <Title level={3}>What will participants be asked to do?</Title>
          <Paragraph>You will be allowed to</Paragraph>
        </div>
      </Space>
    </div>
  );
}
