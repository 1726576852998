import React, { useEffect } from "react";
import { Space, Row, Col, Typography } from "antd";
import Slider from "react-slick";
import "../assets/Home.css";
import PRODUCT_DEMO from '../assets/PRODUCT_DEMO.mp4';

const { Title, Paragraph } = Typography;

const Home = () => {
  useEffect(() => {
    document.title = "Hawaii Home";
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 3500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
      autoplaySpeed: 2000,
    lazyLoad:true
  };
  const slidingImages = ["/home_img2.jpg","/home_img3.jpg","/home_img4.jpg","/home_img5.jpg"];
  return (
    <div>
      <Space size={60} style={{ width: "100%",paddingBottom:'4em'}} direction="vertical">
        <div className="homeBanner">
          <div className="bannerOverLay"></div>
          <p>
            We are Developing a System for Measuring Antibody Response and You
            Can Help
          </p>
        </div>
        <div className="whoWeAreSection">
          <Title
            level={1}
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "50px",
              color: "#053b6fe3",
            }}
          >
            WHO WE ARE
          </Title>
          <Title level={4} className="whoWeAreSubText">{"We Are a Team of Research Scientists Working on COVID 19, to Help Our Community & Loved Once."}</Title>
          <Slider style={{marginTop:'1.5em'}} {...settings}>
         {
             slidingImages.map((images)=> <div key={images}>
             <img src={images} style={{width:'100%'}} />
             </div>)
         }
        </Slider>
        </div>
        <Row justify="center">
            <Col span={12}>
            <Title
            level={1}
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "50px",
              color: "#053b6fe3",
            }}
          >EASY FOUR STEP PROCEDURE</Title>
              <video  src={PRODUCT_DEMO} type="video/mp4" controls style={{width: "100%"}} muted autoPlay={true} loop />
             
            </Col>
        </Row>
        <Row
        className="homeCommunityRows"
          gutter={[48, 8]}
          justify="center"
          align="middle"
        >
          <Col lg={14} md={12} xs={24} sm={24}>
            <Space className="rightFloatPC" direction="vertical">
              <Title className="homeTitles" level={2}>
                Our Efforts For The Community
              </Title>
              <Paragraph className="homeAfterBannerContentP">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry for now.Lorem Ipsum is simply dummy text of the
                printing and typesetting industry for now.Lorem Ipsum is simply
                dummy text of the printing and typesetting industry for
                now.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry for now.Lorem Ipsum is simply dummy text of
                the printing and typesetting industry for now.Lorem Ipsum is
                simply dummy text of the printing and typesetting industry for
                now.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry for now.
              </Paragraph>
            </Space>
          </Col>
          <Col  lg={10} md={10} xs={24} sm={24}>
            <img
              className="homeCommunityImage"
              src="/home_img2.jpg"
              alt="Our Efforts for the community"
            />
          </Col>
        </Row>
        <Row
                 className="homeCommunityRows"

          gutter={[48, 8]}
          justify="center"
          align="middle"
        >
          <Col lg={14} md={10} xs={24} sm={24}>
            <img
              className="rightFloatPC homeCommunityImage"
              src="/home_img2.jpg"
              alt="Our Efforts for the community"
            />
          </Col>
          <Col lg={10} md={12} xs={24} sm={24}>
            <Space direction="vertical">
              <Title className="homeTitles" level={2}>
                Our Efforts For The Community
              </Title>
              <Paragraph className="homeAfterBannerContentP">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry for now.Lorem Ipsum is simply dummy text of the
                printing and typesetting industry for now.Lorem Ipsum is simply
                dummy text of the printing and typesetting industry for
                now.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry for now.Lorem Ipsum is simply dummy text of
                the printing and typesetting industry for now.Lorem Ipsum is
                simply dummy text of the printing and typesetting industry for
                now.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry for now.
              </Paragraph>
            </Space>
          </Col>
        </Row>
        <Row
                  className="homeCommunityRows"

          gutter={[48, 8]}
          justify="center"
          align="middle"
        >
          <Col lg={14} md={12} xs={24} sm={24}>
            <Space className="rightFloatPC"  direction="vertical">
              <Title className="homeTitles" level={2}>
                Our Efforts For The Community
              </Title>
              <Paragraph className="homeAfterBannerContentP">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry for now.Lorem Ipsum is simply dummy text of the
                printing and typesetting industry for now.Lorem Ipsum is simply
                dummy text of the printing and typesetting industry for
                now.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry for now.Lorem Ipsum is simply dummy text of
                the printing and typesetting industry for now.Lorem Ipsum is
                simply dummy text of the printing and typesetting industry for
                now.Lorem Ipsum is simply dummy text of the printing and
                typesetting industry for now.
              </Paragraph>
            </Space>
          </Col>
          <Col lg={10} md={10} xs={24} sm={24}>
            <img
              className="homeCommunityImage"
              src="/home_img2.jpg"
              alt="Our Efforts for the community"
            />
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default Home;
