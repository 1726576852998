import React,{ useEffect} from 'react'
import db from '../services/firebase';

export default function Programs() {
    const [users,setUsers] =React.useState([]);
    useEffect(() => {
        const unsubscribe=db.collection('users').onSnapshot(snapshot =>{
            setUsers(snapshot.docs.map(doc=>{
                return Object.assign({},{
                    id:doc.id,
                },doc.data())
            }))
            
        })
        return ()=>{
            unsubscribe();
        };
    },[])
    return (
        <div>
            {users.map(item=>{
                return <p>{item.name}</p>
            })}
        </div>
    )
}
