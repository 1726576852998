import React from 'react'
import { Space, Form, Typography, Row, Col, Input, Button, Spin, message, Result } from 'antd'
import db from '../services/firebase';
import { withRouter } from 'react-router-dom';
import '../assets/contactus.css';
const { Text, Title } = Typography;


function Contactus(props) {
    const [forms] = Form.useForm();
    const [success, setSuccess] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        return () => {
            setSuccess(false);
        }
    }, [props.history])

    function submitForm(values) {
        setIsLoading(true);
        let { name, email, message } = values;
        db.collection('contactus').add({ name, email, message }).then(() => {
            setSuccess(true);
            setIsLoading(false);
            forms.resetFields();
        }).catch(err => {
            console.error(err);
            setIsLoading(false);
            message.error(err.message);
        });
    }
    return (
        <Space direction="vertical" className="cntSpace" >
            <Title style={{ marginLeft: '12.5vw' }}>Contact Us</Title>
            <Row style={{ marginTop: '3vh' }} className="cntRow" justify="space-around" gutter={[16, 16]}>
                <Col lg={12} xl={12} xxl={12} md={12} xs={24} sm={24}>
                    <Row justify="center" gutter={[16, 16]}>
                        <Col lg={12} xl={12} xxl={12} md={12} xs={21} sm={21}>
                            <Spin spinning={isLoading} tip="Please wait...">
                                {success ? <Result
                                    status="success"
                                    title="Your query submitted successfully"
                                    extra={[
                                        <Button type="primary" onClick={() => props.history.push("/")} shape="round">
                                            Back To Home
                                      </Button>,
                                    ]}
                                /> : <Form layout="vertical" form={forms} onFinish={submitForm} name="nest-messages" >
                                    <Form.Item label="Name" name="name" rules={[
                                        {
                                            required: true,
                                            message: 'Please input your name!',
                                        },
                                    ]}><Input size="large" /></Form.Item>
                                    <Form.Item label="Email" name="email" rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        },
                                    ]}><Input size="large" /></Form.Item>
                                    <Form.Item label="Message" name="message" rules={[
                                        {
                                            required: true,
                                            message: 'Please input your message!',
                                        },
                                    ]}><Input.TextArea size="large" /></Form.Item>
                                    <Form.Item >
                                        <Button type="primary" size="large" block htmlType="submit">Send</Button>
                                    </Form.Item>

                                </Form>}
                            </Spin>

                        </Col>
                    </Row>
                </Col>
                <Col lg={12} xl={12} xxl={12} md={12} xs={24} sm={24}>
                    <div class="mapouter"><div class="gmap_canvas"><iframe id="gmap_canvas" src="https://maps.google.com/maps?q=University%20of%20Hawaii%20Innovation%20Lab%20(iLab)&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div>
                </Col>
            </Row>
        </Space>
    )
}


export default withRouter(Contactus)