import React,{useState} from 'react'
import { Form, Input, Button, Checkbox,Row,Col,Spin } from 'antd';
import '../assets/Signup.css';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import { loginUser } from "../actions";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

function Login(props) {
  const [isLoading,setIsLoading] = useState(false);

    const onFinish = async(values) => {
        setIsLoading(true)
       await props.submitLogin(values.email, values.password);
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      
      React.useEffect(() => {

        if(props.auth.isAuthenticated===true){
            setIsLoading(false)

         props.history.push("/dashboard");

        }
        else{
        if(props.auth.loginError){
            setIsLoading(false)
        }

        }
      },[props.auth])
      
    return (
        <div className="signupContainer">
        <div className="textPart">
            <h2>Hawaii Research Center</h2><br/>
            <span>Please enter you email and password to proceed</span>
        </div>
        <div className="formSection">
        <Row align="middle" justify="center" style={{height:'85vh',overflowY: 'hidden'}}>
           <Col span={20}>
               <Spin spinning={isLoading}>
           <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your Email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input.Password />
          </Form.Item>
    
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Log In
            </Button>
          </Form.Item>
        </Form>
        </Spin>
           </Col>
        </Row>
      
        </div>
</div>
    )
}

const mapStateToProps = (state)=>{
    return {
      auth:state.auth
    };
  }
  
  const mapDispatchToProps = (dispatch)=>{
    return {
      submitLogin:(email, password)=>{dispatch(loginUser(email,password))}
    }
  }
  

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
