import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
} from "../actions/";
import {message} from 'antd';
import Cookies from 'js-cookie';
import {setNotifications} from '../services/notifications'


export default (
  state = {
    isLoggingIn: false,
    isLoggingOut: false,
    isVerifying: false,
    loginError: false,
    logoutError: false,
    isAuthenticated: false,
    user: {},
    consentInfo: {},
  },
  action
) => {
  if(action.type!==LOGOUT_REQUEST && Cookies.get('isAuthenticated')) {
    state.user = Cookies.getJSON('user');
    state.isAuthenticated = Cookies.getJSON('isAuthenticated');
    state.consentInfo= Cookies.getJSON('consentInfo') ?Cookies.getJSON('consentInfo'):state.consentInfo;
  }
  
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: false
      };
    case LOGIN_SUCCESS:
      Cookies.set('user',action.user);
      Cookies.set('isAuthenticated',true);
      Cookies.set('consentInfo',action.consentInfo?action.consentInfo:{});
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user,
        consentInfo:action.consentInfo,
      };
    case SIGNUP_FAILURE:
      message.error('Signup failed!');
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true
      };
      case SIGNUP_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: false
      };
    case SIGNUP_SUCCESS:
      Cookies.set('user',action.user);
      Cookies.set('isAuthenticated',true);
      Cookies.set('consentInfo',action.consentInfo?action.consentInfo:{});
      message.success('Registration success!')
      setNotifications('Thank you for being part of this study.');
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user,
        consentInfo:action.consentInfo,
      };
    case LOGIN_FAILURE:
      message.error('Login failed!');

      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true
      };
    case LOGOUT_REQUEST:
      Cookies.remove('user');
      Cookies.remove('isAuthenticated');
      Cookies.remove('notifications');
      Cookies.remove('consentInfo');
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false
      };
    case LOGOUT_SUCCESS:
      Cookies.remove('user');
      Cookies.remove('isAuthenticated');
      Cookies.remove('notifications');
      Cookies.remove('consentInfo');
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
        user: {},
        consentInfo:{}
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isVerifying: false
      };
      case 'UPDATE_CONSENTINFO':
        let newConsent = {...state};
        console.log("red",newConsent)
        newConsent.consentInfo.consentCompleted=action.payload;
        Cookies.set('consentInfo',newConsent.consentInfo);
        return {
          ...state,
          consentInfo:newConsent
        };
        case 'UPDATE_CONSENTACCEPTANCE':
          let newConsent1 = {...state};
          newConsent1.consentInfo.consentAgreed=action.payload;
        console.log("red",newConsent1)

        Cookies.set('consentInfo',newConsent1.consentInfo);

          return {
            ...state,
            consentInfo:newConsent1
          };
    default:
      return state;
  }
};
