import React,{useState} from "react";
import HeaderComponent from "./components/header";
import "antd/dist/antd.css";
import { Layout} from "antd";
import { BrowserRouter as Router, Route, Switch,Redirect} from "react-router-dom";
import FooterComponent from "./components/footer";
import Home from "./pages/home";
import Programs from "./pages/programs";
import HowItWorks from "./pages/howitworks";
import FAQ from "./pages/faq";
import Profile from "./pages/profile";
import Signup from "./pages/signup";
import Login from "./pages/login";
import Appointments from "./pages/appointments";
import Dashboard from "./pages/dashboard";
import ContactUs from "./pages/contactus";

import { connect } from "react-redux";
import {logoutUser} from './actions';
import SideMenus from "./components/SideMenus";
import Cookies from 'js-cookie';
import {getNotifications} from './services/notifications'


const {   Content } = Layout;

const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  isVerifying,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isVerifying ? (
        <div />
      ) : isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);



function App(props) {
  
  const { isAuthenticated, isVerifying,user, logoutUserMethod } = props;
  
  
  
  return (
    <Router>
      <Layout>
      <HeaderComponent logOff={logoutUserMethod} notifications={getNotifications()} isAuthenticated={isAuthenticated} title="Hawaii" />

    <Layout>
      {isAuthenticated && <SideMenus logOff={logoutUserMethod} />}

        <Content style={{margin:isAuthenticated && (window.location.pathname==="/dashboard"|| window.location.pathname==="/profile" || window.location.pathname==="/appointments") && '2em 2.5em'}}>
          <Switch>
            <Route exact path="/" component={Home} />
          <Route exact path="/programs" component={Programs} />
          <Route exact path="/howitworks" component={HowItWorks} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/signin" component={Login} />
          <Route exact path="/contactus" component={ContactUs} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} isAuthenticated={isAuthenticated} isVerifying={isVerifying} />
          <ProtectedRoute exact path="/appointments" component={Appointments} isAuthenticated={isAuthenticated} isVerifying={isVerifying} />
          <ProtectedRoute exact path="/profile" component={Profile} isAuthenticated={isAuthenticated} isVerifying={isVerifying} />

          </Switch>
          
        </Content>
      </Layout>
      <FooterComponent/>

      </Layout>
    </Router>
  );
}


function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    user:state.auth.user,
    consentInfo: state.auth.consentInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logoutUserMethod:()=>{dispatch(logoutUser())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
