import React, { useState, useEffect } from "react";
import moment from "moment";
import { Calendar, Typography, Row, Col, Drawer, Button, Space, Spin, message, Result,Badge } from "antd";
import { connect } from 'react-redux';
import { CheckCircleFilled } from '@ant-design/icons';
import db from '../services/firebase';
import '../assets/BookAppointment.css';
import {setNotifications} from '../services/notifications'


const maxCandidatesAllowed = 25;

function BookAppointmentDrawer(props) {
    const { visible, user, controls } = props;
    const [bookingDate, setBookingDate] = useState(null);
    const [activeSlot, setActiveSlot] = useState(null);
    const [lastSelected, setLastSelected] = useState(null);
    const [disabledSlots, setDisabledSlots] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const availableSlots={
        3:[
            "04:00 PM - 05:00 PM",
            "05:00 PM - 06:00 PM",
            "06:00 PM - 07:00 PM",
            "07:00 PM - 08:00 PM"
        ],
        6:[
            "10:00 AM - 11:00 AM",
            "11:00 AM - 12:00 PM",
            "12:00 PM - 01:00 PM",
            "01:00 PM - 02:00 PM"
        ]
    }
    

    async function onSelect(value) {
        setIsLoading(true);
        setActiveSlot(null);
        setLastSelected(null);
        try{
            let docRef = await db.collection('appointments').where("bookingDate", "==", value.format('DD-MM-YYYY')).get();
            if (!docRef.empty) {
                setIsLoading(false)
                let counter = await 0,temp={};
                availableSlots[value.day()].map(slots=>temp[slots]=0);
                docRef.forEach((doc) => {
                    counter++;
                    temp[doc.data().activeSlot]++;
                });
                setDisabledSlots(temp);
                if (counter===100) {
                    setBookingDate(null);
                    message.warning("No Seat Available For "+moment(value).format('DD MMMM YYYY'))
                }
                else {
                    
                    setBookingDate(value);
                }
            }
            else {
                setIsLoading(false)
                setDisabledSlots({});
                setBookingDate(value);
            }
        }
        catch(e) {
            setIsLoading(false)
            console.log(e)
            message.error('Internal server error')
        }
        

    }



    const disabledDate = (current) => {
        if (moment(current).isBefore(moment())) {
            return true;
        }
        else {
            if (current.weekday() !== 3 && current.weekday() !== 6) {
                return true
            }
        }
    }

    let clearFields = () => {
        setBookingDate(null);
        setActiveSlot(null);
        setDisabledSlots({});
        setIsLoading(false)
        setLastSelected(null)
    }

    useEffect(() => {
        if (props.controls.activeAppointMentdId) {
            db.collection('appointments').doc(props.controls.activeAppointMentdId).get().then(docRef => {
                if (docRef.exists) {
                    let data = docRef.data();
                    onSelect(moment(data.bookingDate,'DD-MM-YYYY'))
                    setActiveSlot(data.activeSlot);
                    setLastSelected(data.activeSlot)
                }
                else {

                }
            })
        }

        return () => {
            clearFields()
        }
    }, [props.controls.activeAppointMentdId])

    const scheduleAppointment = async () => {
        setIsLoading(true);
        try {
            // let previousAppointMent = await db.collection('appointments').where("uid", "==", user.uid).get();
            if (!controls.activeAppointMentdId) {
                await db.collection('appointments').add({ uid: user.uid, bookingDate: bookingDate.format("DD-MM-YYYY"), activeSlot });
                setIsLoading(false);
                setShowSuccessMessage(true);
            }
            else {

                await db.collection('appointments').doc(controls.activeAppointMentdId).update({ uid: user.uid, bookingDate:bookingDate.format("DD-MM-YYYY"), activeSlot })
                setIsLoading(false);
                setShowSuccessMessage(true);
                setNotifications("Your appointment has been successfully booked.");
            }

        }
        catch (e) {
            setIsLoading(false);
            console.log(e);
            message.error("Internal Server Error!");
        }
    }

    return <Drawer visible={visible}
        width={420}
        title="Book Appointment"
        footer={<>
            {activeSlot && !showSuccessMessage &&
                <Row justify="center">
                    <Col span={24}><Button disabled={isLoading} onClick={() => { scheduleAppointment() }} type={"primary"} block size="large">Submit Booking</Button></Col>

                </Row>}
        </>}
        onClose={() => {clearFields();props.onCancel()}}
        closable={true}>
        <Spin spinning={isLoading}>
            {showSuccessMessage ? <Result
                status="success"
                title="Scheduled Appointment Successfully!"
                extra={[
                    <Button key="appointments" type="primary" onClick={() =>window.location.href="/appointments"}size="medium">Go to Appointments</Button>,
                ]}
            /> : <Space size={20} direction="vertical">
                <Row className="testLocationDrawer">
                <Col span={24}><Typography.Title level={5}>Testing Location -</Typography.Title></Col>
                    <Col span={24}><Typography.Text><b>Manoa Innovation Center</b></Typography.Text></Col>
                    <Col span={24}><Typography.Text>2800 Woodlawn Dr, Honolulu, HI 96822</Typography.Text></Col>
                </Row>
                <Row justify="center">
                    <Col span={24}><Typography.Title level={5}>Pick Date -</Typography.Title></Col>
                    <Col span={24}>
                        <div className="testCalenderDrawer">
                            <Calendar disabledDate={disabledDate} value={bookingDate ? bookingDate : moment()} fullscreen={false} onSelect={onSelect} />
                        </div>
                    </Col>
                </Row>
                {bookingDate && availableSlots[bookingDate.day()] && <Row justify="center" gutter={[1, 20]}>
                    <Col span={24}><Typography.Title level={5}>{bookingDate.date() + " " + moment(bookingDate).format("MMMM") + ", availability"}</Typography.Title></Col>
                    {
                        bookingDate && availableSlots[bookingDate.day()].map((dates)=>{
                           return <Col span={12} key={dates}>
                               <Badge count={activeSlot === dates ? <CheckCircleFilled  style={{ color: 'green',fontSize:'18px' }} /> : 0} >
                                    <Button disabled={disabledSlots[dates]>=maxCandidatesAllowed && lastSelected !== dates } title={disabledSlots[dates]>=maxCandidatesAllowed && lastSelected !== dates && "No Seats Available"} onClick={() => setActiveSlot(dates)} type={ "default"} size="middle">{dates}</Button>
                                </Badge>
                                </Col>
                        })
                    }
                </Row>}

            </Space>}
        </Spin>
    </Drawer>;
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        controls: state.controls,
    };
}

export default connect(mapStateToProps)(BookAppointmentDrawer);
