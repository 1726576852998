import React from "react";
import { Layout, Row, Col, Typography,Space} from "antd";
import "../assets/Footer.css";
const { Footer } = Layout;

export default function FooterComponent() {
  return (
    <Footer>
      <Row gutter={[12, 16]} className="footerRow" justify="end" align="middle">
        <Col lg={19} xl={14} xxl={14} md={22} sm={24} xs={24}>
          <Row gutter={[48, 16]} justify="center" align="middle">
            <Col xxl={4} md={6} lg={4} xs={10} sm={10}>
             <Space size={40} direction="vertical">
             <Typography.Link href="/howitworks">How It Works</Typography.Link>
              <Typography.Link href="/">Our Programs</Typography.Link>
             </Space>
            </Col>
            <Col xxl={4} md={6} lg={4}  xs={10} sm={10}>
             <Space size={40} direction="vertical">
              <Typography.Link href="/signup">Get Started</Typography.Link>
              <Typography.Link href="/signup">Get Started</Typography.Link>
             </Space>
            </Col>
            <Col xxl={4} md={6} lg={4}  xs={10} sm={10}><Space size={40} direction="vertical">
             <Typography.Link href="/faq">FAQ</Typography.Link>
              <Typography.Link href="/">Privacy Policy</Typography.Link>
             
             </Space></Col>
             
            <Col xxl={4} md={6} lg={6}  xs={10} sm={10}><Space size={40} direction="vertical">
              <Typography.Link href="/">{"Terms & Conditions"}</Typography.Link>
              <Typography.Link href="/contactus">Contact Us</Typography.Link>
             </Space></Col>
          
          </Row>
        </Col>
        <Col lg={5} xl={5} md={3} sm={14} xs={14}>
          <img src="/logo.png" style={{width:'80px'}} />
        </Col>
      </Row>
      <Typography.Text style={{textAlign:'center',display:'block',color:'white',marginTop:'5vh'}} level={5}>© 2021 Hawaii. All Rights Reserved.</Typography.Text>
    </Footer>
  );
}
