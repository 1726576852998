import React from 'react';
import { Row, Col, Space, Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

const SubFaqModule = ({ title, content }) => <Space size="small" direction="vertical">
    {title && <Title level={3}>{title}</Title>}
    <Paragraph style={{ fontSize: '17px', textAlign: 'justify' }}>{content}</Paragraph>
</Space>



export default function Faq() {
    let faqContent = [
        {
            title: "Who we are?",
            content: `This project is being done by researchers and community health centers interested in reducing Covid-19 infections in Hawai'i rural communities. The project is funded by the National Institutes of Health (NIH).
            It is part of the NIH RADx-UP, a health research program to learn more about Covid-19 disease. If you join RADx-UP, we will gather some data (information) about you.  
            We will combine these with data from other people who join RADx-UP. We will study the data from all who join to understand how to help more people at risk for or with COVID-19.`
        },
        {
            title: "What is this study about?",
            content: `We are asking you to participate in the PAAC Covid-19 Community Testing program. The Covid-19 pandemic has had a big impact on schools and community organizations that serve children and their families. 

            To reduce the spread of Covid-19 infections, this research project will expand testing for the Covid-19 virus in the community. Testing will be at community sites and include people who do not have symptoms or exposure to the virus. 
            
            This will help us detect the infection as early as possible. Testing will be done at least every week to make sure no new infections have occurred in your organization.
            `
        },
        {
            title: "What method do we use?",
            content: `We will use a method called antigen testing. It is safe and very easy. The tests detect proteins (also called antigens) from the virus. To make this as comfortable as possible, participants will obtain their nasal samples. Results are usually available in about an hour.
 
            The study will help us understand how effective this program is at detecting new infections in the community. If you agree to participate, we will give you an appointment and ask you to complete the following:
            .
            `,
        },
        {
            title: "How long will it take and what information I must provide?",
            content: <Space direction="vertical">
                <ol>
                    <li><b>Survey: </b>Before you come, we will ask that you take a survey either online or through video-teleconferencing. It should take about 20 to 30 minutes to complete.</li>
                    <li>We will ask you for basic information such as your name, date of birth, address, contact information, race, ethnicity, gender, language, health insurance status, disability, job, and household information, including address history. Your name and contact information will only be used to tell you about your test and to keep track of your participation.</li>
                    <li>We will ask you for information about COVID-19, including information about any symptoms (a change in your health) and test results. If you had a positive COVID-19 test, we will ask for information about contact tracing (people who may have meet you while you had COVID-19). We will ask about your medical history and if you have or have not had vaccines and why.</li>
                    <li>We will ask you for information about your health, education, family, home, relationships, behavior, and social life, among others.</li>
                    <li>SARS-CoV-2 Antibody Test: This blood test tells us if you have been infected before by the Covid-19 virus. Like a blood glucose test, we will use a lancet to lightly prick your skin to obtain a drop of blood for the test.</li>
                    <li>SARS-CoV-2 Antigen Test. This test will tell us if you are currently infected with the virus that causes Covid-19. To make it most comfortable, we will have each participant do their nasal sampling. We will provide instructions on how to take the sample and will be present to answer questions. Once the sample is done, we will place it on the antigen test card. Results will be available in about an hour, sometimes sooner.</li>
                </ol>
                <Text><b>Retesting:</b> Sometimes we will need to retest a person on the same day to make sure the results are accurate. All positive tests will be confirmed with a second nasal swab test to make sure the results are correct. If someone tests NEGATIVE but has had serious exposure to Covid-19 or has developed symptoms, we may also ask for a second test</Text>
                <Text>For the second test, we will use a method called PCR. This is a very good method to detect infection, but it usually takes 1-2 days to process. To make sure everyone is safe during this waiting period, we will ask that you leave work and quarantine at home. We will call you as soon as the results are available.</Text>
                <Text>Educational material: You will be provided with educational material about Covid-19 testing, infection, and prevention. These will be in the forms of video, printed material, and online content. Research staff will also be available to answer questions about the results and Covid-19.</Text>
            </Space>
        },
        {
            title: "Is any further testing required?",
            content: `We know that some people are infected with the virus that causes Covid-19 but have no symptoms or very mild symptoms, such as headaches. To detect new infections, we will ask that you do an Antigen Test at least every week. If there is severe Covid-19 exposure at your organization, we may offer more frequent testing.
            `,
        },
        {
            title: "Who can be in this study?",
            content: <ul><li>Adults ≥ 18 years</li><li>Children 10 to 17 years of age, with the consent of their parent(s)</li></ul>,
        },
        {
            title: "Will I get any benefit from being a participant? ",
            content: <Space direction="vertical">
                <Text>Will this study benefit those participating in the program? The results of the tests will be provided to you. We will answer all questions. We believe that this information will help you and your family, and your co-workers make decisions to stay safe and healthy.

                However, we would like to make sure you understand, that even if you test NEGATIVE for the Covid-19 virus, you should continue to take precautions to prevent infection. This includes:
</Text>
                <ol>
                    <li>Wearing a mask when around others</li>
                    <li>Keep your distance, at least 6 feet away, from others.</li>
                    <li>Clean your hands often by washing for 20 seconds or using hand sanitizer with at least 60% alcohol.</li>
                </ol>
                <Text>We think the frequent testing, along with the prevention measures, may reduce the chance of Covid infection spreading to your organization. You will also receive a $5 gift card for your time and effort when you complete the first questionnaire. You may also be eligible for an additional $10 gift card when you complete a follow-up assessment.</Text>
            </Space>,
        },
        {
            title: "Is this study Safe?",
            content: `Are there risks to being in this study? We believe there is little or no risk to you to be part of this interview. The finger-stick causes brief discomfort. If you are uncomfortable completing any of the steps (the survey, the finger stick, or the nasal swab test), you can skip it, take a break, or withdraw from the project altogether.  

            If you experience discomfort from participation in the testing, you can take a break and rest. If your discomfort continues, we help connect you to a doctor who can help you. The cost for this treatment will be charged to your insurance company or to you. The University of Hawaii and the AHARO Health Centers have no program to pay you or compensate you for this treatment.
            `,
        },
        {
            title: "How will you protect my privacy?",
            content: `Your privacy is very important to us. We will take great care to protect your privacy. However, even with our best efforts, there is always a chance that your identity and/or information collected during this study may be accidentally released or seen by unauthorized persons. Here are a few steps we will take:`,
        },
        {
            title: "How my Digital Data will be Secure?",
            content: <ul>
                <li>Data will be stored on protected in secure computer systems. We will limit and keep track of who can see these data. Identifying information, such as your name and phone number, will only be accessible by researchers and their assistants to contact you about results and track your participation in the program.</li>
                <li>Anyone who can see these data will have to use a password and a virtual private network to access it.</li>
                <li>We will take steps to protect your information from others that should not be able to see it.</li>
                <li>When your data are shared with other researchers, they will not have information that can identify you.</li>
                <li>This project has a Certificate of Confidentiality from the United States government. Certificates of Confidentiality protect your privacy by blocking the release of identifiable, sensitive research information to anyone not connected to the research except when you agree or in a few other specific situations.</li>
            </ul>,
        },
        {
            title: "What if I don't want to Participate further?",
            content: `It's Your Choice: You do not need to be in this study if you don't want to. You can also decide to be in the study now but change your mind later and stop. Your decision will not affect your regular medical care now or in the future.`,
        },
        {
            title: "How can you reach us for any further clarification and questions?",
            content: <>If you have any questions about this study, please contact <a href="mailto:support@hia.com">support@hia.com</a></>,
        },
    ]
    return (
        <Row justify="center">
            <Col style={{paddingTop:'3em',paddingBottom:'3em'}} lg={17} xxl={17} xl={17} md={20} xs={20} sm={20}>
                <Space   size="medium" direction="vertical">
                    <Title style={{ textAlign: 'center',fontSize:'3.0vh' }}>Frequently Asked Questions</Title>
                    {
                        faqContent.map((cont) => <SubFaqModule key={cont.title} title={cont.title} content={cont.content} />)
                    }


                </Space>
            </Col>
        </Row>
    )
}
