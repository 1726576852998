import React, { useState } from 'react'
import { Form, Input, Button, Checkbox, Row, Col, Spin, message } from 'antd';
import '../assets/Signup.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { signUpUser } from "../actions";


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: { offset: 8, lg: 16,xl: 16,xxl: 16,md:16,sm:24,xs:24 },
};

function Signup(props) {
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    console.log(values)
    if (values.termsConditions === true) {
      setIsLoading(true);
      await props.submitSignUp(values);
    }
    else {
      message.error('Please accept Terms & Conditions!')
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  React.useEffect(() => {
    if (props.auth.isAuthenticated === true) {
      setIsLoading(false)
      props.history.push("/dashboard");
    }
    else {
      if (props.auth.loginError) {
        setIsLoading(false)
      }
    }

  }, [props.auth])

  return (
    <div className="signupContainer">
      <div className="textPart">
        <h2>Hawaii Research Center</h2><br />
        <span>Get started with us</span>
      </div>
      <div className="formSection">
        <Row align="middle" justify="center" style={{ height: '85vh', overflowY: 'hidden' }}>
          <Col span={20}>
            <Spin spinning={isLoading}>
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="First name"
                  name="firstName"
                  rules={[{ required: true, message: 'Please input your First Name!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Last name"
                  name="lastName"
                  rules={[{ required: true, message: 'Please input your Last Name!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input your Email!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item {...tailLayout} className="tacClass" name="termsConditions" valuePropName="checked" rules={[{ required: true, message: 'Please accept Terms & conditions!' }]}>
                  <Checkbox>I agree to accept <a href="/">Terms & conditions</a></Checkbox>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Register
            </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Col>
        </Row>

      </div>
    </div>

  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitSignUp: (data) => { dispatch(signUpUser(data)) }
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Signup));