import React, { useEffect, useState } from 'react';
import ConsentFormModal from '../components/ConsentFormModal';
import BookAppointmentDrawer from '../components/BookAppointment';
import { Card, Typography, Row, Col, Statistic, Button, Select, Space, Steps, Result, Tooltip } from "antd";
import { connect } from 'react-redux';
import moment from 'moment';
import { LineCharts,DualAxesCharts } from '../components/Charts';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import db from '../services/firebase';
import '../assets/dashboard.css';
const { Step } = Steps;

const customDropDown2 = (dropdownProps) => <Select dropdownClassName="addtocalList" onDropdownVisibleChange={(op) => !op && dropdownProps.onRequestClose({ preventDefault: () => { } })} open={dropdownProps.isOpen} style={{ width: 120, visibility: 'hidden' }} allowClear>

    {dropdownProps.children.map((itm, indx) => <Select.Option style={{ textAlign: 'center' }} value={indx + "cal"}>{itm}</Select.Option>)}
</Select>

const AddToCalendarDropdown = AddToCalendarHOC(Button, customDropDown2);


const { Countdown } = Statistic;

const { Title, Paragraph, Text } = Typography


function Dashboard(props) {
    const [appointmentVisible, setAppointmentVisible] = useState(false);
    const [current, setCurrent] = useState(1);
    const [appointmentList, setAppointmentList] = useState([]);
    let [covidAffectedData,setCovidAffectedData] =useState([]) ;
    const startDatetime = moment().utc().add(2, 'days');
    const endDatetime = startDatetime.clone().add(2, 'hours');
    const duration = moment.duration(endDatetime.diff(startDatetime)).asHours();
    const event = {
        description: 'Description of event. Going to have a lot of fun doing things that we scheduled ahead of time.',
        duration,
        endDatetime: endDatetime.format('YYYYMMDDTHHmmssZ'),
        location: 'NYC',
        startDatetime: startDatetime.format('YYYYMMDDTHHmmssZ'),
        title: 'Super Fun Event',
    }

    

    const consentAttended = [
        { year: '1991', value: 3 },
        { year: '1992', value: 4 },
        { year: '1993', value: 3.5 },
        { year: '1994', value: 5 },
        { year: '1995', value: 4.9 },
        { year: '1996', value: 6 },
        { year: '1997', value: 7 },
        { year: '1998', value: 9 },
        { year: '1999', value: 13 },
    ];

    const getHawaiCovidReports = async () => {
        try {
            let res = await fetch('https://api.covidtracking.com/v1/states/hi/daily.json');
            res=await res.json();
            let temp = [],temp2=[];
            res.map(itm=>{
                if(itm.positiveTestsViral || itm.deathConfirmed){
                    temp.push({
                        type:"active",
                        year:moment(itm.date,'YYYYMMDD').format('DD MMMM YYYY'),
                        value:itm.positiveTestsViral,
                    })
                    temp2.push({
                        type:"deaths",
                        year:moment(itm.date,'YYYYMMDD').format('DD MMMM YYYY'),
                        value:itm.deathConfirmed
                    })
                }
            })
            setCovidAffectedData([...temp.reverse(),...temp2.reverse()])
          
        } catch (er) {
            console.log(er)
        }
    }

    useEffect(() => {
        getHawaiCovidReports()
        if (!props.consentInfo.consentCompleted) {
            props.openConsentDrawer(true)
        }
        else {
            setCurrent(2);
            props.openConsentDrawer(false)
        }
        setAppointmentVisible(props.controls.appointmentBookingVisible)
        const unsubscribe = db.collection('appointments').where("uid", "==", props.user.uid).onSnapshot(snapshot => {
            if (!snapshot.empty) {
                let appoints = [];
                snapshot.forEach((doc) => {
                    appoints.push(Object.assign({}, { id: doc.id }, doc.data()))
                });
                setAppointmentList(appoints);
            }

        })
        return () => {
            unsubscribe();
        };
    }, [props.consentInfo.consentCompleted, props.controls.appointmentBookingVisible])

    const steps = [
        { description: "Registration", title: "Getting Started" },
        { description: "Complete brief intake questionnaire and book your appointment", title: "Health Questionnaire" },
        { description: "Attend your appointment at the Manoa Innovation Center to complete first antibody test and review your results", title: "First AntiBody Test" },
        { description: "Complete final antibody test to look at your trajectory", title: "Final AntiBody Test" }
    ]

    return (
        <div style={{ minHeight: '62vh', paddingBottom: '1em' }}>
            <Title level={2}>Overview</Title>
            <Row style={{ marginTop: '3em' }} justify="center" gutter={[16, 16]}>
                <Col lg={24}>
                    <Card>
                        <Steps
                            size="large"
                            current={current}
                        >
                            {steps.map((step, indx) => {
                                return <Step
                                    title={step.title}
                                    description={step.description}
                                />
                            })}

                        </Steps>
                    </Card>

                </Col>
            </Row>
            <Row style={{ marginTop: '3em' }} justify="center" gutter={[16, 16]}>
                {/* <Col lg={9} xl={9} xxl={9} md={9} sm={24} xs={24}>
                    <Card>
                        <Title level={4}>Users Participated</Title><br />
                        <LineCharts statsData={consentAttended} />
                    </Card>
                </Col> */}
                <Col lg={18} xl={18} xxl={18} md={18} sm={24} xs={24}>
                    <Card>
                        <Title level={4}>COVID Report</Title><br />
                        <DualAxesCharts yField="value" xField="year"  data={covidAffectedData} />
                    </Card>
                </Col>
                <Col lg={6} xl={6} xxl={6} md={6} sm={24} xs={24}>

                    {appointmentList.length > 0 ? <Card>
                        <Space direction="vertical" level={"large"}>
                            <Countdown title="Upcoming Appointment" value={moment('12-05-2021', 'DD-MM-YYYY')} format="DD:HH:mm:ss" />
                            <AddToCalendarDropdown
                                buttonText="Add To Calendar"
                                event={event}
                            />
                        </Space>
                    </Card> : <Result
                        status="404"
                        title="No Appointments"
                        subTitle="Book an appointment today to track upcoming appointment here"
                        extra={props.consentInfo.consentCompleted ? <Button type="primary" onClick={() => setAppointmentVisible(true)}>Book Appointment</Button> : <Tooltip title="Please complete your health questionnaire"><Button type="primary" disabled >Book Appointment</Button></Tooltip>}
                    />}

                </Col>
            </Row>
            <ConsentFormModal closeModal={() => props.openConsentDrawer(false)} openAppointMent={() => { props.openAppointMentDrawer(true); props.openConsentDrawer(false) }} visible={props.controls.healthQuestionerVisible} />
            <BookAppointmentDrawer onCancel={() => { setAppointmentVisible(false); props.openAppointMentDrawer(false) }} visible={appointmentVisible} />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        consentInfo: state.auth.consentInfo,
        controls: state.controls
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        openAppointMentDrawer: (toggleState) => {
            dispatch({
                type: "TOGGLE_APPOINTMENT_BOOKING",
                payload: toggleState
            })
        },
        openConsentDrawer: (toggleState) => {
            dispatch({
                type: "TOGGLE_HEALTH_QUESTIONER",
                payload: toggleState
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);