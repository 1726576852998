import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Row, Col, Input, Typography, Form, Button, Space, Avatar, Popover, Modal, message,Spin,Upload } from 'antd'
import BookAppointmentDrawer from '../components/BookAppointment';
import { UserOutlined, MailOutlined } from '@ant-design/icons'; 
import { auth } from '../services/firebase';
import firebase from 'firebase';
import {verifyAuth} from '../actions/auth';
import '../assets/profile.css';

// Get a non-default Storage bucket

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 6,
    },
};


const ChangePasswordPopUP = (props) => {
    const [isLoading, setIsLoading]= useState(false)
    const [forms] = Form.useForm();

    const reauthenticate = (currentPassword) => {
            var user = auth.currentUser;
            var cred = firebase.auth.EmailAuthProvider.credential(
                user.email, currentPassword);
            return user.reauthenticateWithCredential(cred);
       
      }
    const onFinish = (values) => {
        setIsLoading(true);
        reauthenticate(values.password).then((ref) => {
            var user = auth.currentUser;
            user.updatePassword(values.new_password).then(() => {
                setIsLoading(false);
                forms.setFieldsValue({ password: "",new_password:""})
                message.success("Password updated!");
                props.onCancel();
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);

                message.error("Internal Server Error!")
            });
        }).catch(err => {
            setIsLoading(false);

            if (err.code == "auth/wrong-password") {
                message.error("Invalid Current Password!")
            }
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Modal visible={props.visible} onCancel={() => props.onCancel()}
            title="Change Password"
            footer={[]}
        >
            <Spin spinning={isLoading}>
            <Form
            form={forms}
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Current password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Current Password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="New Password"
                    name="new_password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your New Password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button block type="primary" size="medium" htmlType="submit">
                        Submit
        </Button>
                </Form.Item>
            </Form>
            </Spin>
           
        </Modal>
    )
}

const { Title, Link, Text } = Typography

function Profile(props) {
    let { user } = props
    const [isLoading, setIsLoading]= useState(false)
    const [changePasswordVisible, setChangePasswordVisible] = useState(false);
    const uploadProps = {
        name: 'file',
        showUploadList:false,
        customRequest:(file)=>{
            setIsLoading(true)
            var storageRef = firebase.storage().ref();
            storageRef.child('profilePictures/'+file.file.name).put(file.file).then(async(snapshot) => {
                let uploadedURL=await snapshot.ref.getDownloadURL();
                await auth.currentUser.updateProfile({photoURL:uploadedURL});
                setIsLoading(false);
                props.refreshAuth();
              }).catch(error=>{
                setIsLoading(false);
                  console.log("error",error);
                  message.error("Internal Server Error!");
              });
        },
      };
    return (
        <div style={{ height: '60vh' }}>
            <Row gutter={[16, 10]}>
                <Col lg={5} xl={5} xxl={5} md={5} xs={24} sm={24}>
                    <Popover placement="bottom" title={null} content={<Upload {...uploadProps}><Link >Change Picture</Link></Upload>}>
                        <Spin spinning={isLoading} tip="Please Wait...">
                        <Avatar src={user.photoURL?user.photoURL:null} hape="square" style={{ cursor: 'pointer', borderRadius: '6px' }} size={250} icon={user.photoURL?null:<UserOutlined size="large" />} />
                            </Spin>
                    </Popover>
                </Col>
                <Col lg={10} xl={10} xxl={10} md={10} xs={12} sm={12}>
                    <Space style={{ width: "100%", height: '100%' }} size={130} direction="vertical">
                        <Title className="profileName">{user.displayName}</Title>
                        <Row gutter={[5, 10]} justify="start" align="middle">
                            <Col span={2}><MailOutlined style={{ fontSize: '1.5em', color: 'grey' }} /></Col>
                            <Col style={{ marginLeft: '-1em', marginTop: '-5px' }} span={12}><Text style={{ fontSize: '1.5em', color: 'grey' }}>{user.email}</Text></Col>
                        </Row>
                    </Space>
                </Col>
                <Col span={7} style={{ marginTop: '5px' }}>
                    <Button type="primary" size="large" shape="round" onClick={() => { setChangePasswordVisible(true) }}>Change Password</Button>
                </Col>
            </Row>
            <ChangePasswordPopUP visible={changePasswordVisible} email={user.email} onCancel={() => setChangePasswordVisible(false)} />
            <BookAppointmentDrawer onCancel={() => { props.openAppointMentDrawer(false) }} visible={props.controls.appointmentBookingVisible} />
        </div>

    )
}



const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        controls: state.controls
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveAppointment: (aptId) => {
            dispatch({
                type: 'SET_ACTIVE_APPOINTMENT', payload: aptId
            })
        },
        refreshAuth:()=> {dispatch(verifyAuth())},
        openAppointMentDrawer: (toggleState) => {
            dispatch({
                type: "TOGGLE_APPOINTMENT_BOOKING",
                payload: toggleState
            })
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Profile)