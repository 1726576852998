import Cookies from 'js-cookie';


const setNotifications=async(message)=>{
    let notifications= await Cookies.getJSON('notifications')?Cookies.getJSON('notifications'):[];
    notifications.push(message);
    await Cookies.set('notifications',notifications);
    return true;
}

const getNotifications=async()=>{
    return  Cookies.getJSON('notifications')?Cookies.getJSON('notifications'):[];
}


export {setNotifications, getNotifications};