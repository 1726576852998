import React, { useState,useEffect } from 'react'
import { Layout, Menu, Typography, Avatar, Space, Row, Col, Button,Tooltip  } from "antd";
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom';
import {
    isBrowser,
    isMobile
  } from "react-device-detect";
import {
    BarChartOutlined,
    FileOutlined,
    UserOutlined,
} from '@ant-design/icons';
import '../assets/SideMenu.css';
const { Sider } = Layout;

function SideMenus({ user, openAppointMent, controls, consentInfo, openConsentDrawer, logOff,history }) {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKeys, setSelectedKeys] = React.useState([]);

    const onCollapse = val => {
        setCollapsed(val)
    };

    useEffect(() => {
        setSelectedKeys([window.location.pathname]);
        if(!isMobile){
            setCollapsed(false);
        }
        return () => {
          setSelectedKeys([]);
        };
      }, [window.location.pathname,isMobile,consentInfo]);
    if( !selectedKeys.includes("/contactus") && !selectedKeys.includes("/faq") && !selectedKeys.includes("/howitworks")  && !selectedKeys.includes("/")  )
    return (
        <Sider collapsedWidth={0} collapsible className="SideMenu" style={{minHeight:'70vh',paddingTop:'3em'}} collapsed={collapsed} onCollapse={onCollapse}>
            <Space size={20} style={{ padding: '1em' }} direction="vertical">
                <Row align='middle' justify="center" gutter={[10, 10]}>
                    <Col span={12}> <Avatar src={user?.photoURL?user?.photoURL:null} size={80} icon={user?.photoURL?null:<UserOutlined />} /></Col>
                    <Col span={24} style={{ color: "white", textAlign: 'center' }}><Typography.Text style={{ color: "white" }}>{user?.displayName}</Typography.Text></Col>
                </Row>
                <Row justify="center">
                    <Col> <Button type="primary" style={{ backgroundColor: '#194bff', borderColor: '#194bff' }} onClick={() => { consentInfo.consentCompleted ? openAppointMent(!controls?.appointmentBookingVisible) : openConsentDrawer(!controls?.healthQuestionerVisible) }}>
                        {consentInfo.consentCompleted ? "BOOK APPOINTMENT" : "Health Questioner"}
                    </Button></Col>
                </Row>

            </Space>
            <Menu theme="dark" selectedKeys={selectedKeys} mode="inline">

                <Menu.Item key="/dashboard"  onClick={()=>history.push("/dashboard")} icon={<BarChartOutlined />}>
                    Dashboard
            </Menu.Item>
               
            {!consentInfo.consentCompleted ? <Menu.Item key="/profile" disabled onClick={() => history.push("/profile")} icon={<UserOutlined />}>
                    <Tooltip placement="right" title="Please complete your health questionnaire">
                    Profile</Tooltip>
                </Menu.Item> :  <Menu.Item key="/profile" onClick={()=>history.push("/profile")} icon={<UserOutlined />}>
                    Profile
            </Menu.Item>}

                <Menu.Item key="/reports" icon={<FileOutlined />}>
                    Reports
            </Menu.Item>
                {!consentInfo.consentCompleted ? <Menu.Item key="/appointments" disabled onClick={() => history.push("/appointments")} icon={<FileOutlined />}>
                    <Tooltip placement="right" title="Please complete your health questionnaire">
                        Appointments</Tooltip>
                </Menu.Item> : <Menu.Item key="/appointments" onClick={() => history.push("/appointments")} icon={<FileOutlined />}>
                    Appointments
            </Menu.Item>}

            </Menu>
            <Space size={20} style={{ padding: '1em', position: 'absolute', width: '100%' }} direction="vertical">

                {!collapsed && <Row justify="center">
                    <Col span={24}> <Button block type="primary" style={{ backgroundColor: '#194bff', borderColor: '#194bff' }} onClick={() => { logOff() }}>
                        Log Out
            </Button></Col>
                </Row>}

            </Space>
        </Sider>
    )
    else{
        return <></>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        controls: state.controls,
        consentInfo: state.auth.consentInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openAppointMent: (toggleState) => {
            dispatch({
                type: "TOGGLE_APPOINTMENT_BOOKING",
                payload: toggleState
            })
        },
        openConsentDrawer: (toggleState) => {
            dispatch({
                type: "TOGGLE_HEALTH_QUESTIONER",
                payload: toggleState
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideMenus));