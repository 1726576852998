import firebase from 'firebase';
const {REACT_APP_FIREBASE_APIKEY,REACT_APP_FIREBASE_AUTHDOMAIN,REACT_APP_FIREBASE_DATABASE_URL,REACT_APP_FIREBASE_PROJECT_ID,REACT_APP_FIREBASE_STORAGEBUCKET,REACT_APP_FIREBASE_MESSAGINGSENDER_ID,REACT_APP_FIREBASE_APP_ID,REACT_APP_FIREBASE_MEASUREMENT_ID} = process.env;

var firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_APIKEY,
    authDomain: REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGINGSENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID
  };


  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const db = firebaseApp.firestore();
  const auth = firebaseApp.auth();

  export { auth,firebaseApp };
  export default db;