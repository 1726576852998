import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  Row,
  Button,
  Col,
  message,
  Form,
  Input,
  DatePicker,
  Switch,
  Select,
  Steps,
  InputNumber,
  Spin,
  Result,
  notification,
  Checkbox 
} from "antd";
import "../assets/ConsentForm.css";
import db from "../services/firebase";
import { logoutUser } from "../actions";
import { connect } from "react-redux";
import moment from "moment";
import { setNotifications } from '../services/notifications'
import validator from 'validator';


const { Step } = Steps;

function ConsentFormModal(props) {
  const [currentSteps, currentSetSteps] = useState(0);
  const [loadingButton, setLoadingButton] = useState("");
  const [isVaccinated, setIsVaccinated] = useState(false);
  const [isCovidPositive, setIsCovidPositive] = useState(false);
  const [forms] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [personalInformation, setFormInfo] = useState({ data: {} });
  const [disableConsentBtn,setDisableConsentBtn] = useState(true);
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);


  const rejectUserConsent = () => {
    //for rejecting the consent
    setLoadingButton("No");
    db.collection("test-user")
      .doc(props.user.uid)
      .update({ consentAttended: true })
      .then(function () {
        setLoadingButton("");
        const args = {
          message: 'Alert',
          description:
            "Thank you for your time. Your response has been recorded.",
          duration: 0,
        };
        notification.error(args);

        props.logoutUserMethod();
      })
      .catch((err) => {
        setLoadingButton("");

        console.log(err);
        message.error("Internal Server Error!");
      });
  };

  const acceptUserConsent = () => {
    //for accepting the consent
    setLoadingButton("Yes");
    db.collection("test-user")
      .doc(props.user.uid)
      .update({ consentAttended: true, consentAgreed: true })
      .then(function () {
        setLoadingButton("");
        props.updateConsentAcceptance();
        currentSetSteps(1);
      })
      .catch((err) => {
        setLoadingButton("");

        console.log(err);
        message.error("Internal Server Error!");
      });
  };

  const fetchPreviousSave = async () => {
    let questioners = await db
      .collection("health_questioner")
      .where("uid", "==", props.user.uid)
      .get();
    if (!questioners.empty) {
      let activeSurvey = {};
      questioners.forEach((doc) => {
        activeSurvey.data = doc.data();
        activeSurvey.id = doc.id;
      });
      if (activeSurvey.data.personal_information) {
        activeSurvey.data.personal_information.dob = moment(
          activeSurvey.data.personal_information.dob
        );
        let formData = { ...activeSurvey.data.personal_information };
        setFormInfo(activeSurvey);
        setIsLoading(false);

        if (activeSurvey.data.vaccination_information) {
          //saving vaccination informations saved
          activeSurvey.data.vaccination_information.vaccination_date = activeSurvey
            .data.vaccination_information.vaccination_date
            ? moment(activeSurvey.data.vaccination_information.vaccination_date)
            : "";
          activeSurvey.data.vaccination_information.covid_infected_date = activeSurvey
            .data.vaccination_information.covid_infected_date
            ? moment(
              activeSurvey.data.vaccination_information.covid_infected_date
            )
            : "";
          formData = {
            ...formData,
            ...activeSurvey.data.vaccination_information,
          };
          setIsVaccinated(
            activeSurvey.data.vaccination_information?.isVaccinated
          );
          forms.setFieldsValue({...formData});

          setIsCovidPositive(
            activeSurvey.data.vaccination_information?.isCovidPositive
          );
          console.log(forms.getFieldsValue(true))
          if(activeSurvey.data.physical_information){
          currentSetSteps(4);

          }
          else{
            currentSetSteps(3);

          }
        } else {
          forms.setFieldsValue(formData);
          currentSetSteps(2);
        }
      } else {
        setIsLoading(false);
        currentSetSteps(1);
      }
    }
    else {
      if(props.consentInfo?.consentAgreed){
        currentSetSteps(1);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.consentInfo?.consentAgreed) {
      if (props.consentInfo?.consentCompleted) {
        currentSetSteps(4);
      }
      else {
        fetchPreviousSave();
      }
    }
    else {
      setIsLoading(false);
    }
  }, [props.visible,props.consentInfo.consentAgreed, forms]);


  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }

  const submitPersonal = async (values) => {
    if (
      !shallowEqual(
        personalInformation.data?.personal_information
          ? personalInformation.data.personal_information
          : {},
        values
      )
    ) {
      values.dob = moment(values.dob).toString();
      values.address2 = values.address2 ? values.address2 : "";

      setIsLoading(true);
      let saveInfoRequest = personalInformation.id
        ? db
          .collection("health_questioner")
          .doc(personalInformation.id)
          .update({ personal_information: values })
        : db
          .collection("health_questioner")
          .add({ uid: props.user.uid, personal_information: values });

      saveInfoRequest
        .then(function (docRef) {
          setIsLoading(false);
          if (!personalInformation.data.vaccination_information) {
            forms.setFieldsValue({ isVaccinated: false, isCovidPositive: false });
          }
          let activeSurvey = { ...personalInformation };
          activeSurvey.data.personal_information = values;
          activeSurvey.id=personalInformation.id?personalInformation.id:docRef.id;
          setFormInfo(activeSurvey);
          setIsVaccinated(false);
          setIsCovidPositive(false);
          currentSetSteps(2);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          message.error("Internal Server Error !");
        });
    } else {
      if (!personalInformation.data.vaccination_information) {
        forms.setFieldsValue({ isVaccinated: false, isCovidPositive: false });
      }
      setIsVaccinated(false);
      setIsCovidPositive(false);
      currentSetSteps(2);
    }
  };

  const saveVaccinationInformation = async (values) => {
    if (
      !shallowEqual(
        personalInformation.data?.vaccination_information
          ? personalInformation.data.vaccination_information
          : {},
        values
      )
    ) {
      values.isVaccinated = values.isVaccinated ? values.isVaccinated : false;
      values.isCovidPositive = values.isCovidPositive
        ? values.isCovidPositive
        : false;
      values.vaccination_date = values.vaccination_date
        ? moment(values.vaccination_date).toString()
        : "";
      values.covid_infected_date = values.covid_infected_date
        ? moment(values.covid_infected_date).toString()
        : "";

      setIsLoading(true);
      db.collection("health_questioner")
        .doc(personalInformation.id)
        .update({ vaccination_information: values })
        .then(function () {
          setIsLoading(false);
          // forms.setFieldsValue({ isVaccinated: false });
          // forms.setFieldsValue({ isCovidPositive: false });
          let activeSurvey = { ...personalInformation };
          activeSurvey.data.vaccination_information = values;
          setFormInfo(activeSurvey);
          currentSetSteps(3);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          message.error("Internal Server Error !");
        });
    } else {
      currentSetSteps(3);
    }
  };


  const submitQuestioner = async (values) => {
    setIsLoading(true);
    values.existing_medical_conditions = values.existing_medical_conditions  ? values.existing_medical_conditions  : true;
    Promise.all([db.collection("health_questioner")
      .doc(personalInformation.id)
      .update({ physical_information: values }), db.collection("test-user")
        .doc(props.user.uid)
        .update({ consentCompleted: true })]).then(() => {
          setNotifications("Your health questionnaire has been successfully completed.");

          setIsLoading(false);
          currentSetSteps(4);
        }).catch(err => {
          console.log(err);
          setIsLoading(false);
          message.error("Internal Server Error!");
        })
  };

  const layout = {
    labelCol: { span: currentSteps === 1 ? 10 : 12 },
    wrapperCol: { span: 10 },
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  const handleScroll=(event) => {
    const target=event.target;
    console.log(parseInt(target.scrollHeight-target.scrollTop),target.clientHeight)
    if(parseInt(target.scrollHeight-target.scrollTop)<=target.clientHeight){
      setDisableConsentBtn(false);
    }
  }

  return (
    <Modal
      className="consentModal"
      title={
        currentSteps === 0
          ? <Typography.Title style={{textAlign: 'center'}} level={4}>Consent</Typography.Title>
          : "We first need to collect some basic health information in order to personalize our assessment"
      }
      visible={props.visible}
      onCancel={() => props.consentInfo.consentAgreed && props.closeModal()}
      footer={[
        currentSteps === 0 ? (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Button
                loading={loadingButton === "Yes"}
                type={"primary"}
                block
                disabled={disableConsentBtn}
                onClick={() => acceptUserConsent()}
              >
                {loadingButton !== "Yes" && "Yes"}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type={"danger"}
                loading={loadingButton === "No"}
                onClick={() => {
                  rejectUserConsent();
                }}
                disabled={disableConsentBtn}

                block
              >
                {loadingButton !== "No" && "NO"}
              </Button>
            </Col>
          </Row>
        ) : (
          []
        ),
      ]}
    >
      {currentSteps === 4 ? (
        <Result
          status="success"
          title="Thank you for completing the initial health questionnaire."
          subTitle="We are now ready to book your initial appointment."
          extra={[
            <Button
              size="large"
              onClick={() => {
                props.openAppointMent();
                props.updateConsentStatus();
              }}
              type="primary"
              key="console"
            >
              Book Appointment
            </Button>,
          ]}
        />
      ) : (
        <Spin spinning={isLoading}>
          {currentSteps === 0 ? (
            <div style={{height:'400px',overflowY: 'scroll'}} onScroll={handleScroll}><Typography.Paragraph >
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
           
            I am giving consent to take part in this study. I understand that
            I may withdraw myself from participation at any time, and this
            will not affect my medical care or my child’s medical care. I can
            request a copy of this consent form by emailing{" "}
            <a href="mailto:support@hia.com">support@hia.com</a>
          </Typography.Paragraph></div>
          ) : (
            <>
              <Steps progressDot current={currentSteps - 1}>
                <Step title="Personal Information" />
                <Step title="Vaccination Information" />
                <Step title="Physical Information" />
              </Steps>

              {currentSteps === 1 ? (
                <Form
                  onFinishFailed={onFinishFailed}
                  initialValues={{ remember: true }}
                  onFinish={submitPersonal}
                  style={{ marginTop: "4em" }}
                  form={forms}
                  {...layout}
                >
                  <Form.Item
                    label="DOB"
                    name="dob"
                    rules={[{ required: true, message: "Please select dob!" }]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={disabledDate}
                      placeholder="Select Date"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Street Address1"
                    name="address1"
                    rules={[
                      {
                        required: true,
                        message: "Please enter street address!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Street Address2" name="address2">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[{ required: true, message: "Please enter city!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Zip Code"
                    name="zip"
                    rules={[{ required: true, message: "Please enter zip!" },({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || validator.isPostalCode(value,'US')) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Please enter a valid zip code!'));
                      },
                    }),]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Mobile Phone"
                    name="mobile_phone"
                    rules={[
                      {
                        required: true,
                        message: "Please enter mobile number!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || validator.isMobilePhone(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Please enter a valid mobile number!'));
                        },
                      }),
                    ]}
                  >
                   
                        <Input prefix={"+1"} defaultValue={forms.getFieldValue('mobile_phone')} />
                     
                  </Form.Item>
                  <Row
                    style={{ marginTop: "5em", marginBottom: "-3em" }}
                    gutter={[5, 48]}
                    justify={"end"}
                  >
                    <Col span={4}>
                      <Form.Item>
                        <Button type="primary" shape="round" htmlType="submit">
                          Next
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              ) : currentSteps === 2 ? (
                <>
                  <Form
                    form={forms}
                    onFinishFailed={onFinishFailed}
                    initialValues={{ remember: true }}
                    onFinish={saveVaccinationInformation}
                    style={{ margin: "4em auto 0 auto", width: '60%' }}
                    layout="vertical"
                    {...{
                      labelCol: { span: 22 },
                      wrapperCol: { span: 4 },
                    }}
                  >
                    <Form.Item className="isVaccined" label="Have you received a COVID-19 Vaccine?" name="isVaccinated">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={forms.getFieldValue("isVaccinated")}
                        onChange={(val) => {
                          forms.setFieldsValue({ isVaccinated: val });
                          setIsVaccinated(val);
                        }}
                      />
                    </Form.Item>
                    {isVaccinated && (
                      <>
                        <Form.Item
                          label="Vaccine Type"
                          name="vaccine_type"
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select vaccine type!",
                            },
                          ]}
                        >
                          <Select
                            value={forms.getFieldValue("vaccine_type")}
                            defaultValue={forms.getFieldValue("vaccine_type")}
                            onChange={(val) => { forms.setFieldsValue({ vaccine_type: val }); forceUpdate() }}

                          >
                            {["Pfizer", "Moderna", "Johnson & Johnson"].map((val) => <Select.Option value={val}>{val}</Select.Option>)}
                          </Select>
                        </Form.Item>
                        {["Pfizer", "Moderna"].includes(forms.getFieldValue("vaccine_type")) && <Form.Item
                          label="How many doses?"
                          name="vaccine_doses"
                          labelCol={{ span: 14 }}
                          wrapperCol={{ span: 5 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select no. of vaccine dose!",
                            },
                          ]}
                        ><Select
                          value={forms.getFieldValue("vaccine_doses")}
                          defaultValue={forms.getFieldValue("vaccine_doses")}
                          onChange={(val) => { forms.setFieldsValue({ vaccine_doses: val }); forceUpdate() }}

                        >
                            {[1, 2].map((val) => <Select.Option value={val}>{val}</Select.Option>)}
                          </Select></Form.Item>}
                        <Form.Item
                          label="What is the date of your last dose?"
                          name="vaccination_date"
                          labelCol={{ span: 20 }}
                          wrapperCol={{ span: 14 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select date of your last dose!",
                            },
                          ]}
                        >
                          <DatePicker
                            disabledDate={disabledDate}
                            style={{ width: "100%" }}
                            placeholder="Select Date"
                            value={forms.getFieldValue("vaccination_date")}
                            defaultValue={forms.getFieldValue(
                              "vaccination_date"
                            )}
                          />
                        </Form.Item>
                      </>
                    )}
                    <Form.Item label="Have you been diagnosed with COVID-19 in the past?" name="isCovidPositive">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={forms.getFieldValue("isCovidPositive")}
                        onChange={(val) => {
                          forms.setFieldsValue({ isCovidPositive: val });
                          setIsCovidPositive(val);
                        }}
                      />
                    </Form.Item>
                    {isCovidPositive && (
                      <Form.Item
                        label="When was the approximate date of infection?"
                        name="covid_infected_date"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 14 }}
                        rules={[
                          {
                            required: true,
                            message: "Please select approximate date of infection!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          disabledDate={disabledDate}
                          placeholder="Select Date"
                          value={forms.getFieldValue("covid_infected_date")}
                          defaultValue={forms.getFieldValue(
                            "covid_infected_date"
                          )}
                        />
                      </Form.Item>
                    )}
                    <Row
                      style={{ marginTop: "5em", marginBottom: "-3em" }}
                      gutter={[5, 48]}
                      justify={
                        (currentSteps === 2) | (currentSteps === 3)
                          ? "space-between"
                          : "end"
                      }
                    >
                      <Col span={4}>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => {
                              currentSetSteps(currentSteps - 1);
                            }}
                            shape="round"
                          >
                            Back
                          </Button>
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                          >
                            Next
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </>
              ) : (
                <>
                  <Form
                    onFinishFailed={onFinishFailed}
                    initialValues={{ remember: true }}
                    onFinish={submitQuestioner}
                    style={{ marginTop: "4em" }}
                    {...layout}
                  >
                    <Form.Item
                      label="Weight"
                      name="weight"
                      rules={[
                        {
                          required: true,
                          message: "Please enter weight!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || validator.isFloat(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Please enter weight in Lbs!'));
                          },
                        }),
                      ]}
                    >
                      <Input suffix="LBS" min={1} style={{ width: "100%" }} />
                    </Form.Item>
                    
                    <Row justify="end" gutter={[0, 16]}>
                      <Col span={11}>
                        <Form.Item
                          label="Height:"
                          name="height_feet"
                          labelCol={{ span:12}}
                          wrapperCol={{span:10}}
                          rules={[
                            {
                              required: true,
                              message: "Please enter height in feet!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || validator.isNumeric(value)) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error('Please enter height in feet!'));
                              },
                            }),
                          ]}
                        >
                          <Input suffix="feet" style={{ width: "100%" }} />
                        </Form.Item>

                      </Col>
                      <Col span={7}>
                        <Form.Item
                          name="height_inches"
                          wrapperCol={{span:16}}
                          rules={[
                            {
                              required: true,
                              message: "Please enter height in inches!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || validator.isNumeric(value)) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error('Please enter height in inches!'));
                              },
                            }),
                          ]}
                        >
                          <Input suffix="inches" style={{ width: "100%" }} />
                        </Form.Item>

                      </Col>
                    </Row>
                    <Form.Item
                    labelCol={{span:24}}
                    wrapperCol={{span:24}}
                      label="Do you have any pre-existing conditions (please select  all that apply)"
                      name="existing_medical_conditions"
                    >
                          <Checkbox.Group options={[
                            { label: 'Hypertension', value: 'Hypertension' },
                            { label: 'Diabetes', value: 'Diabetes' },
                            { label: 'Obesity', value: 'Obesity'},
                            { label: 'Heart Disease', value: 'Heart Disease'},
                            { label: 'Lung Disease', value: 'Lung Disease'},
                            { label: 'HIV', value: 'HIV'},
                            { label: 'Cancer', value: 'Cancer'},
                            { label: 'Liver Disease', value: 'Liver Disease'},
                            { label: 'Kidney Disease', value: 'Kidney Disease'},
                            { label: 'Neurological Disorder', value: 'Neurological Disorder'},
                            { label: 'Other', value: 'Other'},
                          ]} onChange={(checkedValues)=>{forms.setFieldsValue({existing_medical_conditions:checkedValues});forceUpdate()}} defaultValue={forms.getFieldValue('existing_medical_conditions')} />
                    </Form.Item>

                    {
                       forms.getFieldValue('existing_medical_conditions')!==undefined && forms.getFieldValue('existing_medical_conditions').indexOf('Other')!==-1 &&  <Form.Item
                       label="Other Medical Condition"
                       name="other_existing_medical_conditions"
                       rules={[
                         {
                           required: true,
                           message: "Please enter other medical conditions!",
                         },
                       ]}
                     >
                       <Input style={{ width: "100%" }} />
                     </Form.Item>
                    }
                     {forms.getFieldValue('existing_medical_conditions')!==undefined && forms.getFieldValue('existing_medical_conditions').length>0 && <Form.Item
                     className="preExisting_Med"
                      label=" Are you taking any medication for these pre-existing conditions?"
                      name="medications_for_pre_existing_conditions"
                      labelCol={{ span:18}}
                      wrapperCol={{ span:9}}
                    >
                      <Switch
                        style={{ marginLeft: "1em" }}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                      />
                    </Form.Item>}
                    <Form.Item
                      label="Who is your primary care provider?"
                      name="primary_care_provider"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Primary Care Provider!",
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                      label="Insurance Provider"
                      name="insurance_provider"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Insurance Provider!",
                        },
                      ]}
                    >
                      <Select
                            value={forms.getFieldValue("insurance_provider")}
                            defaultValue={forms.getFieldValue("insurance_provider")}
                            onChange={(val) => { forms.setFieldsValue({ insurance_provider: val });}}
                          >
                            {["HMSA", "Kaiser", "Medicare/Quest","Other"].map((val) => <Select.Option value={val}>{val}</Select.Option>)}
                          </Select>
                    </Form.Item>
                   
                    <Row
                      style={{ marginTop: "5em", marginBottom: "-3em" }}
                      gutter={[5, 48]}
                      justify={
                        (currentSteps === 2) | (currentSteps === 3)
                          ? "space-between"
                          : "end"
                      }
                    >
                      <Col span={4}>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => {
                              currentSetSteps(currentSteps - 1);
                            }}
                            shape="round"
                          >
                            Back
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </>
          )}
        </Spin>
      )}
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    consentInfo: state.auth.consentInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logoutUserMethod: () => {
      dispatch(logoutUser());
    },
    updateConsentStatus: () => { dispatch({ type: "UPDATE_CONSENTINFO", payload: true }) },
    updateConsentAcceptance: () => { dispatch({ type: "UPDATE_CONSENTACCEPTANCE", payload: true }) }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentFormModal);
