export default (
    state = {
     appointmentBookingVisible:false,
     healthQuestionerVisible:false,
     activeAppointMentdId:null
    },
    action
  ) => {
   
    switch (action.type) {
      case 'TOGGLE_APPOINTMENT_BOOKING':
        return {
          ...state,
          appointmentBookingVisible:action.payload,
        };
        case 'TOGGLE_HEALTH_QUESTIONER':
        return {
          ...state,
          healthQuestionerVisible:action.payload,
        };
        case 'SET_ACTIVE_APPOINTMENT':
        return {
          ...state,
          activeAppointMentdId:action.payload,
        };
      default:
        return state;
    }
  };
  