import db,{ firebaseApp } from "../services/firebase";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST
  };
};

const receiveLogin = (user,consentInfo) => {

  return {
    type: LOGIN_SUCCESS,
    user,
    consentInfo
  };
};

const loginError = () => {
  return {
    type: LOGIN_FAILURE
  };
};

const requestSignup = () => {
  return {
    type: SIGNUP_REQUEST
  };
};

const receiveSignup = (user,consentInfo) => {
  return {
    type: SIGNUP_SUCCESS,
    user,
    consentInfo
  };
};

const signupError = () => {
  return {
    type: SIGNUP_FAILURE
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS
  };
};

export const signUpUser = (data) => dispatch => {
  dispatch(requestSignup());
  firebaseApp
    .auth()
    .createUserWithEmailAndPassword(data.email, data.password)
    .then(async(userCred) => {
      await userCred.user.updateProfile({displayName:data.firstName+" "+data.lastName});
      // debugger;
      await db
        .collection("test-user")
        .doc(userCred.user.uid)
        .set({
          email: data.email,
          name: userCred.user.displayName,
          consentAttended: false,
          consentAgreed: false,
          consentCompleted: false,
        });
        let userDB={
          consentAttended: false,
          consentAgreed: false,
          consentCompleted: false,
        };
       dispatch(receiveSignup(userCred.user,userDB));
    })
    .catch(error => {
      console.log(error);
      //Do something with the error if you want!
      dispatch(signupError());
    });
};

export const loginUser = (email, password) => dispatch => {
  dispatch(requestLogin());
  firebaseApp
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async(user) => {
      let userDbData=await db.collection('test-user').doc(user.uid).get();
      if(userDbData.exists){
        let data=await userDbData.data();
        let userDB = await {
          consentAgreed:data?.consentAgreed,
          consentAttended:data?.consentAttended,
          consentCompleted:data?.consentCompleted,
        };
        dispatch(receiveLogin(user,userDB));
      }
      
    })
    .catch(error => {
      console.log(error);
      //Do something with the error if you want!
      dispatch(loginError());
    });
};

export const logoutUser = () => dispatch => {
  dispatch(requestLogout());
  firebaseApp
    .auth()
    .signOut()
    .then(() => {
      dispatch(receiveLogout());
    })
    .catch(error => {
      //Do something with the error if you want!
      dispatch(logoutError());
    });
};

export const verifyAuth = () => dispatch => {
  dispatch(verifyRequest());
  firebaseApp.auth().onAuthStateChanged(async(user) => {
    if (user !== null) {
      let userDbData=await db.collection('test-user').doc(user.uid).get();
      let data=await userDbData.data();
      let userDB =await {
        consentAgreed:data?.consentAgreed,
        consentAttended:data?.consentAttended,
        consentCompleted:data?.consentCompleted,
      };
      dispatch(receiveLogin(user,userDB));
    }
    dispatch(verifySuccess());
  });
};
