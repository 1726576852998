import React, { useEffect, useState } from 'react'
import { List, Button, Typography, message,Spin } from "antd";
import db from '../services/firebase';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment'
import BookAppointmentDrawer from '../components/BookAppointment';
function Appointments(props) {
    const [appointmentList, setAppointmentList] = useState([]);
    const [isLoading,setIsLoading]=useState(true);

    useEffect(() => {
        const unsubscribe=db.collection('appointments').where("uid", "==", props.user.uid).onSnapshot(snapshot =>{
            // setUsers(snapshot.docs.map(doc=>{
            //     return Object.assign({},{
            //         id:doc.id,
            //     },doc.data())
            // }))
            setIsLoading(false);
            if (!snapshot.empty) {
                let appoints = [];
                snapshot.forEach((doc) => {
                    appoints.push(Object.assign({}, { id: doc.id }, doc.data()))
                });
                setAppointmentList(appoints);
            }
            
        })
        return ()=>{
            unsubscribe();
        };
    }, [])

    const setReschedule=async(id)=>{
        await props.setActiveAppointment(id);
        props.openAppointMentDrawer(true);
    }

    return (
        <div>
            <Typography.Title style={{ paddingBottom: '1em' }} level={2}>My Appointments</Typography.Title>
            <Spin spinning={isLoading}>

            <List  dataSource={appointmentList}
            
                renderItem={(item)=><List.Item style={{border:'2px solid grey',padding:'1.5em',borderRadius:'3px'}}
                    actions={[<Button shape="round" type="primary" round onClick={()=>setReschedule(item.id)}>Reschedule</Button>]}
                >
                    <List.Item.Meta

                        title={<Typography.Title level={3}>{moment(item.bookingDate,'DD-MM-YYYY').format('DD MMMM YYYY')}</Typography.Title>}
                        description={<Typography.Title level={5}>Manoa Innovation Center</Typography.Title>}
                    />
                    <div>{item.activeSlot}</div>
                </List.Item>}
                />
            </Spin>

                <BookAppointmentDrawer onCancel={()=>{props.openAppointMentDrawer(false)}} visible={props.controls.appointmentBookingVisible} />
        </div>

    )
}


const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        controls: state.controls
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveAppointment: (aptId) => {
            dispatch({
                type: 'SET_ACTIVE_APPOINTMENT', payload: aptId
            })
        },
        openAppointMentDrawer:(toggleState)=>{dispatch({
            type:"TOGGLE_APPOINTMENT_BOOKING",
            payload:toggleState
        })},
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Appointments))
