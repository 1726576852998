import { Layout, Menu, Button, Row, Col, Badge, Popover, Empty, List } from "antd";
import React, { useState, useEffect } from "react";
import "../assets/Header.css";
import { BellOutlined } from '@ant-design/icons';
import { getNotifications } from '../services/notifications'

const { SubMenu } = Menu;
const { Header } = Layout;

const HeaderComponent = (props) => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [notificationList, setNotificationList] = useState([]);

  React.useEffect(() => {
    getNotifications().then((notifications) => {
      setNotificationList(notifications)

    })
    setSelectedKeys([window.location.pathname]);
    return () => {
      setSelectedKeys([]);
      setNotificationList([])
    };
  }, [props.notifications]);

  const urls = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Our Programs",
      menus: (
        <>
          <SubMenu key="SubMenu2" title="Vaccines Availables">
            <Menu.Item key="pFizer">pFizer</Menu.Item>
            <Menu.Item key="Covaxin">Covaxin</Menu.Item>
          </SubMenu>
          <Menu.Item key="setting:1">Option 1</Menu.Item>
          <Menu.Item key="setting:2">Option 2</Menu.Item>
        </>
      ),
    },
    {
      title: "How it works",
      url: "/howitworks",
    },
  ];
  if (props.isAuthenticated) {
    if (selectedKeys.includes("/")) {
      urls[0] = {
        title: "Dashboard",
        url: "/dashboard",
      };
    }
    else if (selectedKeys.includes("/howitworks") || selectedKeys.includes("/faq") || selectedKeys.includes("/contactus")) {
      urls[0] = {
        title: "Home",
        url: "/",
      };
      urls.splice(1, 0, {
        title: "Dashboard",
        url: "/dashboard",
      })
    }
    else {
      urls[0] = {
        title: "Home",
        url: "/",
      };
    }
  }
  const handleMenuClick = (url) => {
    window.location.href = url;
  };



  const notifictionContent = () => (<List
    itemLayout="horizontal"
    dataSource={notificationList}
    renderItem={item => (
      <List.Item>
        <List.Item.Meta
          description={item}
        />
      </List.Item>
    )}
  />)
  // console.log(selectedKeys)
  return (
    <Header >
      <img src="/logo.png" className="logo" />
      <div style={{ paddingBottom: '1em' }} className="middleMenuWrapper">
        <Menu
          theme="dark"
          mode="horizontal"
          className="centerMenus"
          selectedKeys={selectedKeys}
        >
          {urls.map((urls, indx) => {
            return urls.menus ? (
              <SubMenu key="SubMenu" title={urls.title}>
                {urls.menus}
              </SubMenu>
            ) : (
              <Menu.Item
                className="leftNavMenus"
                onClick={() => handleMenuClick(urls.url)}
                key={urls.url}
              >
                <span>{urls.title}</span>
              </Menu.Item>
            );
          })}
          <Menu.Item className="rightNavMenuItems">
            <Row gutter={[16, 16]} justify="space-between">
              {props.isAuthenticated ? <><Col span={12} style={{ marginTop: '5px' }}>
                <Popover placement="bottom" title={"Notifications"} overlayStyle={{ width: '22vw' }} className="notificationPopup" content={notifictionContent} trigger="click">
                  <Badge style={{ right: '12px', top: '4px' }} size="small" count={notificationList.length} >
                    <BellOutlined style={{ color: 'white', fontSize: '1.5em' }} />
                  </Badge>
                </Popover></Col>
                {(selectedKeys.includes("/howitworks") || selectedKeys.includes("/") || selectedKeys.includes("/faq") || selectedKeys.includes("/contactus") )&& <Col span={12}>
                  <Button
                    onClick={() => (props.logOff())}
                    type="primary"
                  >
                    Log Out
                </Button>
                </Col>}</> : <><Col span={12}>

                  <Button onClick={() => (window.location.href = "/signin")}>Log In</Button>
                </Col>
                <Col span={12}>
                  <Button
                    onClick={() => (window.location.href = "/signup")}
                    type="primary"
                  >
                    Get Started
                </Button>
                </Col></>}
            </Row>
          </Menu.Item>
        </Menu>
      </div>
    </Header>
  );
};

export default HeaderComponent;
